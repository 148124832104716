import React, {useEffect} from "react";
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import toast from 'react-hot-toast';
import axios from "axios";
import {existOperation,existDevelopment, getMessageWhatsapp, getPhonesText, makeItemsNav, scrollToElement} from '../../helpers/helper.rendering'
import { useLocation } from "@reach/router";

import {newSletterTokko,updateFields} from '../../redux/contactDucks'

import { Helmet } from "react-helmet"
import { getPropertiesAction, REDUX_UPDATE_FILTERS } from "../../redux/propertiesDucks";
import { GET_CONFIGURATIONS_ACTION, GET_INVESTOR_ACTION } from "../../redux/settingsDucks";
// Static logo
import staticLogo from '../../images/bullrich-footer.png'
import Whatsapp from '../../images/icon-whatsapp.png'

const Footer = ({filters,modules,api_key,investor,curFilters,developments,development,property,novelties,dispatch}) => {

  const [open,setOpen] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  const [flag,setFlag] = useState(false)

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        template_version
        env { 
          APISERVER
          TENANTID
          CLIENTID
        }
        proFeatures{
          branchesWa
        }
        design  {
          icons
        }
        logo
        global_email
        fiscal_data
        bot_cliengo
        global_whatsapp
        global_phone
        global_broker_id
        keys{
            captcha
        }
        social{
          facebook
          instagram
          linkedin
          youtube
          twitter
        }
        branch_office{
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          footer {
              title_contact
              title_social
              title_newsletter
              title_office
              button_contact {
                  link
                  value
              }
          }
        }
      }
  }`) 

  const [closeWhatsapp,setCloseWhatsapp] = useState(true)

  const { design: { icons },env} = realEstate
  const { template_version,proFeatures } = realEstate
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''
  const MediaPro = template_version === 'PRO' ? true : false;
  const {branchesWa} = proFeatures;
  const [disabled,setDisabled] = useState(true)

  const INVESTOR_CONFIGURATION = modules.find(config => config.modules.find(module => module.code === 'INVESTOR')) // (STEP) 6 -- Esto es para buscar entre el array que devuelve el back, si hay alguna configuración para alguno de estos 3 modulos
  const OWNER_CONFIGURATION = modules.find(config => config.modules.find(module => module.code === 'OWNER'))
  const BLOG_CONFIGURATION = modules.find(config => config.modules.find(module => module.code === 'BLOG'))

  const [datos, setDatos] = useState({
    email: "",
  });
  const handleInputChange = (e) => {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value,
      });
  };
  const sendForm = (e) => {
    e.preventDefault();
    dispatch(newSletterTokko())
    document.getElementById("formNewsletter").reset();
    return toast.success("Te suscribiste a nuestro newsletter correctamente.") 
  }
  // Función que devuelve el año actual
  useEffect(() => {
    dispatch(GET_INVESTOR_ACTION(env))
    dispatch(GET_CONFIGURATIONS_ACTION()) //(STEP) 5 -- Llamo con dispatch la función de redux que quiero ejecutar
  },[])

  useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();
    if(!realEstate?.keys?.captcha){
      setDisabled(false)
    }
    else{
        setDisabled(true)
    }
  }, [datos]);

  const callCliengo = () => {
    if(!flag){

      var ldk = document.createElement('script');
      ldk.type = 'text/javascript';
      ldk.async = true;
      ldk.src = realEstate.bot_cliengo;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ldk, s);
      setFlag(true)
    }
  }

  const checkActive = (_pathname) => {
    if(_pathname === 'inicio' && pathname === ''){return true}
    if((curFilters.operation === "alquiler-temporario" && _pathname === 'alquiler temp.')){
        return true
    }
    if((_pathname === 'venta' || _pathname === "alquiler" && _pathname !== 'alquiler temp.') && pathname.includes('venta') || pathname.includes('alquiler')){
        if(curFilters.operation === 'alquiler' && _pathname === 'alquiler'){
            return true;
        }
        if(curFilters.operation === 'venta' && _pathname === 'venta'){
            return true;
        }
    }
    else{
        if(pathname === (_pathname)){
            return true;
        }
    }
    return false
  }

  const staticBody = () => {
    var body = document.body;
    body.classList.remove("overflow-hidden");
  }

  const fillFilters = (item) => {
    return {
        location: [],
        type: [],
        operation: item,
        environments:'',
        bedrooms:'',
        price:{
          type:'',
          priceMin:'',
          priceMax:''
        },
        surface:{
          type:'',
          surfaceMin:"",
          surfaceMax:"",
        },
        environments_types: [],
        generals: [],
        services: [],
        specials: [],
      }
  }

  const get_whatsApp_element = () => {
    if(pathname.includes('alquiler') || pathname.includes('venta')){return null}
    if(branchesWa){
      if(realEstate.branch_office.length === 0){
        return(
            <>
              <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-none d-lg-flex "}></a>
              <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-flex d-lg-none "}></a>
            </>
        ) 
      }
      else{
        return(
          <>
          <div onClick={() => setCloseWhatsapp(!closeWhatsapp)} class={"icon-whatsapp contact-btn d-lg-flex div-whatsapp " + (closeWhatsapp ? 'close' : 'open')}>
            <div className={"float-whatsapp " + (realEstate.branch_office.length === 1 ? 'only-one' : '')}>
              {
                realEstate.branch_office.slice(0,2).map((element,index) => (
                  element.contact.whatsapp.length > 0 && <>
                    
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + element.contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"contact-btn d-none d-lg-flex "}>{element.name}</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + element.contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"contact-btn d-flex d-lg-none "}>{element.name}</a>
                  </>
                ))
              }
            </div>
          </div>
          </>
        )
      }
    }
    else{
      return <>
        <span className="animated-text">Contactanos</span>
        <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-none d-lg-flex "}></a>
        <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-flex d-lg-none "}></a>
      </>
    }
  }
  const getCurrentYear = () => new Date().getFullYear();
  const customLogo = true;

  return realEstate.sections && pathname !== "contacto" ? (
    <>
      {isBrowser && 
        <Helmet>
          {
            realEstate.bot_cliengo ? 
              callCliengo()
              :
                ''
            }
        </Helmet>
      }
      <div className="sup-footer">
          <div className="container-fluid sup-footer-container position-relative">
            <button onClick={() => {scrollToElement("main")}} className="gotop-btn d-flex align-items-center justify-content-center">
              <i className="icon-arrow-light"></i>
            </button>
            <div class={"fixed-bar position-fixed " + (((realEstate.global_whatsapp === '' && !branchesWa)))}>
              <img src={Whatsapp} className="whatsapp" alt="Logo de whatsapp pie de pagina" />
              {get_whatsApp_element()}
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 order-1 mb-5 mb-lg-3 text-center text-lg-start">
                  <Link to="/"><img src={customLogo ? staticLogo : realEstate.logo}></img></Link> 
              </div>
              <div className="col-12 col-lg-4 order-2 mb-5 mb-lg-3">
                  <div className="container directions text-center text-lg-start">
                    <h5 className="f-gotham-medium mb-4 text-center text-lg-start">NUESTRA OFICINA</h5>
                    <p>VIAMONTE 524 . CP1053</p>
                    <p>CIUDAD AUTÓNOMA DE BUENOS AIRES, ARG.</p>
                    <p>TEL: + (0054) 11 4313 - 7570</p>
                    <p>WS: <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + "91149288006"} target="_blank" className="">+54 9 11 4928 - 8006</a></p>
                    <a href="mailto:info@bullrichcampos.com.ar">INFO@BULLRICHCAMPOS.COM.AR</a>
                  </div>
              </div>
              <div className="col-12 col-lg-4 order-3 mb-5 mb-lg-3">
                <div className="container item-contact social row mx-auto mb-0 text-center text-lg-start">
                    <h5 className="f-gotham-medium mb-4 text-center text-lg-start">NUESTROS CANALES</h5>
                    <div className="links-container">
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=100092663592744" className="d-inline-block me-2 me-lg-4">
                          <i className={`icon-facebook${iconsStyle}`}></i></a>
                        <a target="_blank" href="https://www.instagram.com/bullrichcampos/" className="d-inline-block me-2 me-lg-4">
                          <i className={`icon-instagram${iconsStyle}`}></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/bullrich-campos-s.a./about/" className="d-inline-block me-2 me-lg-4">
                          <i className={`icon-linkedin${iconsStyle}`}></i></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UC0Aufc9UwFcTA-DjVBKIjGQ" className="d-inline-block">
                          <i className={`icon-youtube${iconsStyle}`}></i></a>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-4 order-4 mt-lg-4 mt-5 d-none d-lg-block"></div>
              <div className="col-12 col-lg-4 order-5 mt-lg-4 mt-5">
                  <div className={"container content-contact mt-auto " + (investor.id ? '' : 'enabled-border')}>
                    <h5 className="f-gotham-medium text-center text-lg-start">SUSCRÍBASE A NUESTRO NEWSLETTER</h5>
                    <form onSubmit={sendForm} id="formNewsletter" >
                      <div className="d-flex mb-lg-3">
                          <input type="email" onChange={handleInputChange} name="email" required placeholder="Ingrese su email" className="w-100 w-lg-auto m-0" />
                          <button
                            disabled={disabled}
                            type="submit" className="submit icon-email-light"></button>
                      </div>
                      { realEstate?.keys?.captcha ?
                          <div className="d-flex justify-content-center justify-content-lg-start mt-4">
                            <ReCAPTCHA
                              sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                              onChange ={() => setDisabled(false)}
                              /> 
                          </div>: '' }
                    </form>
                  </div>
              </div>
              <div className="col-12 col-lg-4 order-last mt-lg-4 mt-4">
                {/* (STEP) 7 -- Te fijas las configuraciones si no estan vacias para pintarlas en el front */}
                {(INVESTOR_CONFIGURATION?.id || OWNER_CONFIGURATION?.id) && <div className={"container item-contact row mb-0 button-separator mx-auto"}>
                  <h5 className="f-gotham-medium conditional-height text-center text-lg-start">PLATAFORMA BULLRICH</h5>
                  {INVESTOR_CONFIGURATION?.id &&
                    <div className="d-block mb-4 p-0">
                      <a className="btn btn-investor d-flex d-lg-inline-flex justify-content-lg-center justify-content-between align-items-center" target={"_blank"} href={'https://mediacore.app/sesion/inicio/'+INVESTOR_CONFIGURATION.url_login}>
                        <span className="text">{INVESTOR_CONFIGURATION.text_cta}</span>
                        <span className="separator mx-2 fs-2" style={{fontWeight: '100',fontFamily: 'system-ui', lineHeight: '0'}}>|</span>
                        <i className={`icon-investor-access${iconsStyle} me-0`}></i>
                      </a>
                    </div>
                  }
                  {OWNER_CONFIGURATION?.id &&
                    <div className="d-block p-0">
                      <a className="btn btn-investor d-flex d-lg-inline-flex justify-content-lg-center justify-content-between align-items-center" target={"_blank"} href={'https://mediacore.app/sesion/inicio/'+OWNER_CONFIGURATION.url_login}>
                        <span className="text">{OWNER_CONFIGURATION.text_cta}</span>
                        <span className="separator mx-2 fs-2" style={{fontWeight: '100',fontFamily: 'system-ui', lineHeight: '0'}}>|</span>
                        <i className={`icon-investor-access${iconsStyle} me-0`}></i>
                      </a>
                    </div>
                  }
                </div>}
              </div>
            </div>
            <div className="container-fluid mt-lg-5">
              <p className="text-center text-footer">TODAS LAS MEDIDAS ENUNCIADAS SON MERAMENTE ORIENTATIVAS, LAS MEDIDAS EXACTAS SERÁN LAS QUE SE EXPRESEN EN EL RESPECTIVO TÍTULO DE PROPIEDAD DE CADA INMUEBLE. TODAS LAS FOTOS, IMÁGENES Y VIDEOS SON MERAMENTE ILUSTRATIVOS Y NO CONTRACTUALES. LOS PRECIOS ENUNCIADOS SON MERAMENTE ORIENTATIVOS Y NO CONTRACTUALES.</p>
            </div>
          </div>      
      </div>
      <footer>
          <div className="container-fluid">
            <div className="d-flex align-items-start text-center justify-content-center">
              <p className="copy white">
                Promoted  by <a target="_blank"  href="https://mediahaus.com.ar"> MediaHaus</a> - Powered by <a target="_blank" className="white" href="https://mediacore.app/"> MediaCore</a><br />
                <span className="all-right">Todos los derechos reservados ® {getCurrentYear()}</span>
              </p>
            </div>
          </div>
      </footer>
    </>
  ):''
}

export default connect(state => ({
  filters:state.properties.totalListFilters,
  api_key:state.settings.keys.tokko,
  investor:state.settings.investor,
  modules:state.settings.modules,
  curFilters:state.properties.filters,
  developments:state.developments.developments,
  novelties:state.novelties.grid_novelties,
  property:state.properties.property,
  development:state.developments.development
}),null)(Footer);