import { Link } from "gatsby"
import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { connect } from "react-redux"
import { makeItemsNav } from "../../helpers/helper.rendering"

import {
  getPropertiesAction,
  getTotalListFilters,
  REDUX_UPDATE_FILTERS,
} from "../../redux/propertiesDucks"
import { getDevelopmentsAction } from "../../redux/developmentsDucks"
import { getNoveltiesAction } from "../../redux/noveltiesDucks"
import { getActionsAction } from "../../redux/actionsDucks"
import { getLastVideo, checkVideo } from "../../helpers/helper.properties"
import { useGetBlogsQuery } from "../../redux/mediacore/blog"

const MenuNav = ({
  filters,
  curFilters,
  api_key,
  developments,
  novelties,
  novelty,
  dispatch,
  setOpen,
  development
}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        template_version
        design {
          icons
        }
        global_phone
        global_email
        global_broker_id
        global_phone
        social {
          facebook
          instagram
          linkedin
          youtube
          twitter
        }
        branch_office {
          name
          address
          city
          region
          broker_id

          contact {
            phone
            mail
            whatsapp
          }
        }
        env {
          APISERVER
          TENANTID
          CLIENTID
        }
        sections {
          footer {
            title_contact
            title_social
            title_newsletter
            title_office
            button_contact {
              link
              value
            }
          }
        }
      }
    }
  `)

  const {
    design: { icons },
    env,
    template_version,
  } = realEstate
  const iconsStyle =
    icons === "Solid" || icons === "Light" ? `-${icons.toLowerCase()}` : ""
  const MediaPro = template_version === "PRO" ? true : false
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  useEffect(() => {
    if (api_key) {
      dispatch(getDevelopmentsAction())
      dispatch(getTotalListFilters())
    }
  }, [api_key])

  const { data: allBlogData, isLoading, isError } = useGetBlogsQuery(26);

  useEffect(() => {
    dispatch(getActionsAction(env))
    // dispatch(getNoveltiesAction(env, true))
  }, [])

  const staticBody = () => {
    var body = document.body
    body.classList.remove("overflow-hidden")
  }

  const checkActive = _pathname => {
    if(_pathname.toLowerCase() === 'legado' && pathname.toLowerCase() === 'novedades'){ //Validacion de novedad circual
      if(novelty?.tags?.find(tag => tag?.name?.toLowerCase() === 'circular')){
        return true;
      }
    }
    else if(_pathname.toLowerCase() === 'novedades' && pathname.toLowerCase() === 'novedades' && novelty?.tags?.find(tag => tag?.name?.toLowerCase() === 'circular')){ //Validacion de una novedad circula (No activar novedades)
        return false;
    }
    else{
      if (_pathname === "home" && pathname === "") {
        return true;
      } else {
        if (pathname === _pathname) {
          return true;
        }
      }
    }
    return false
  }

  const fillFilters = item => {
    return {
      location: [],
      type: [],
      operation: item === "ALQUILER TEMP." ? "alquiler-temporario" : item,
      environments: "",
      bedrooms: "",
      price: {
        type: "",
        priceMin: "",
        priceMax: "",
      },
      surface: {
        type: "",
        surfaceMin: "",
        surfaceMax: "",
      },
      environments_types: [],
      generals: [],
      services: [],
      specials: [],
    }
  }

  const isDetailDevelopment =
    location.pathname.includes("seleccion") && location.pathname.length > 15

  function scrollTo(id) {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset - 9 * 16;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  return realEstate.sections ? (
    <ul className={"nav-menu " + (setOpen ? "open" : "close")}>
      {!isDetailDevelopment ? (
        makeItemsNav(developments, filters, allBlogData?.news?.filter(n => !n.tags.find(t => t.name.toLowerCase() === 'circular') ), MediaPro, 2).map(
          (item, index) => (
            <li
              key={index}
              onClick={() =>
                staticBody() +
                (item.name.toLowerCase() === "venta" ||
                item.name.toLowerCase() === "alquiler" ||
                item.name.toLowerCase() === "alquiler temp."
                  ? dispatch(
                      REDUX_UPDATE_FILTERS(fillFilters(item.name), item.name)
                    ) +
                    setOpen(false) +
                    dispatch(getPropertiesAction())
                  : dispatch(
                      REDUX_UPDATE_FILTERS(fillFilters("venta"), "venta")
                    ))
              }
              className={checkActive(item.name.toLowerCase()) ? "active" : ""}
            >
              <Link
                onClick={() => staticBody() + setOpen(false)}
                className="white"
                to={item.link}
              >
                {item.name}
              </Link>
            </li>
          )
        )
      ) : (
        <>
          <li>
            <a className="white" onClick={() => setOpen(false) + staticBody() + scrollTo("descripcion-scroll")}>
              Descripción
            </a>
          </li>
          <li>
            <a className="white" onClick={() => setOpen(false) + staticBody() + scrollTo("galeria-scroll")}>
              Galería
            </a>
          </li>
          <li>
            <a
              className="white"
              onClick={() => setOpen(false) + staticBody() + scrollTo("caracteristicas-scroll")}
            >
              Características
            </a>
          </li>
          {development?.files?.length > 0 && <li>
            <a className="white" onClick={() => setOpen(false) + staticBody() + scrollTo("descargas-scroll")}>
              Descargas
            </a>
          </li>}
          {checkVideo(development) && <li>
            <a className="white" onClick={() => setOpen(false) + staticBody() + scrollTo("video-scroll")}>
              Video
            </a>
          </li>}
          <li>
            <a className="white" onClick={() => setOpen(false) + staticBody() + scrollTo("ubicacion-scroll")}>
              Ubicación
            </a>
          </li>
          <li>
            <a className="white" onClick={() => setOpen(false) + staticBody() + scrollTo("contacto-scroll")}>
              Contacto
            </a>
          </li>
        </>
      )}
    </ul>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    filters: state.properties.totalListFilters,
    curFilters: state.properties.filters,
    novelties: state.novelties.grid_novelties,
    novelty: state.novelties.novelty,
    api_key: state.settings.keys.tokko,
    developments: state.developments.developments,
    development: state.developments.development,
  }),
  null
)(MenuNav)
