import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"
import { useLocation } from "@reach/router"
import NavMenu from "./menu-nav"

import { cleanDevelopmentAction } from "../../redux/developmentsDucks"
import {
  clearPropertiesAction,
  clearPropertyAction,
  getAllLocationsAction,
} from "../../redux/propertiesDucks"
// Static logo
import staticLogo from "../../images/bullrich-header.png"
import CamposLogo from "../../images/bullrich-detail.png"
import { Toaster } from "react-hot-toast"

function Main({
  settings,
  dispatch,
  updaterFavorites,
  siteTitle,
  api_key,
  allLocations,
  logo,
}) {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        template_version
        proFeatures {
          compare
        }
        design {
          icons
        }
      }
    }
  `)

  const template_version = realEstate.template_version
  const icons = realEstate.design.icons
  const iconsStyle =
    icons === "Solid" || icons === "Light" ? `-${icons.toLowerCase()}` : ""
  const [open, setOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const location = useLocation()
  const isDetailDevelopment = location.pathname.includes("seleccion") && location.pathname.length > 15

  const MediaPro = template_version === "PRO" ? true : false

  useEffect(() => {
    if (!location.pathname.includes("/emprendimientos")) {
      dispatch(cleanDevelopmentAction())
    }
    if (!location.pathname.includes("/propiedad")) {
      dispatch(clearPropertyAction())
    }
    dispatch(clearPropertiesAction())
  }, [location])

  useEffect(() => {
    if (api_key && !allLocations?.locations) {
      dispatch(getAllLocationsAction())
    }
  }, [api_key, allLocations])

  const staticBody = () => {
    var body = document.body
    body.classList.toggle("overflow-hidden")
  }

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  const customLogo = true

  return (
    <header
      id="header"
      className={
        "blur " +
        (open ? "opened" : "closed") +
        (scrolled ? " onScroll " : "") +
        (isDetailDevelopment ? " detailDeveolpment " : "")
      }
    >
      <Toaster
        toastOptions={{
            className: 'toast-className',
            style: {
                maxWidth: 'fit-content',
                
              },
        }} />
      <nav className={"brand " + (location.pathname === "/" || location.pathname.includes("/contacto") || (location.pathname.includes("novedades/") && location.pathname.length > 15) ? " black" : "")}>
        {isDetailDevelopment && (
          <div className="top-header">
            {" "}
            <div className="container-fluid">
              <p className="small"> Propiedad seleccionada y certificada por Bullrich Campos</p>{" "}
              <Link className="btn btn-black" to="/seleccion">Volver a SELECCIÓN</Link>
            </div>{" "}
          </div>
        )}
        <div className="container-fluid fluid-mobile">
          <div className="row justify-content-lg-center justify-content-between align-items-center">
            <div className="col-6 offset-md-5 col-md-5 offset-lg-0 col-lg-2 justify-content-start">
              <Link to={isDetailDevelopment ? '/seleccion' : "/"} className="logo">
                <img
                  className="logo-header"
                  alt="Logo del encabezado"
                  src={
                    customLogo
                      ? isDetailDevelopment
                        ? CamposLogo
                        : staticLogo
                      : logo
                  }
                />
              </Link>
            </div>
            <div className="col-2 col-lg-10 text-right d-flex justify-content-end">
              <NavMenu setOpen={setOpen} />
              <button
                className="d-block d-lg-none"
                id="toggle-nav"
                onClick={() => setOpen(!open) + staticBody()}
              >
                <div className="d-flex align-items-center">
                  <span className="status_nav d-none d-lg-inline">MENÚ </span>
                  <span className="ms-4">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default connect(
  state => ({
    settings: state.settings,
    api_key: state.settings.keys.tokko,
    allLocations: state.properties.allLocationFilters,
    updaterFavorites: state.properties.updaterFavorites,
  }),
  null
)(Main)
