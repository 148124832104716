import { useLocation } from "@reach/router"
import * as React from "react"
import { useEffect } from "react"
import { connect } from "react-redux"
import AlertTop from './v1/Global/Modules/MediaPush/AlertTop'
import FlapButton from './v1/Global/Modules/MediaPush/FlapButton'
import CounterPopup from "./v1/Global/Modules/MediaPush/CounterPopup"
import SquarePop from './v1/Global/Modules/MediaPush/SquarePop'
import {getActionByPage, getActionByType, getCodeFromType, getSectionFromCode, getTypeFromCode} from '../helpers/helper.actions'


const Modules = ({actions,development={}}) => {

  const { pathname } = useLocation()
  const isViewDevelopment = pathname?.split("/")[1]?.includes("seleccion");

  return (
    <>
      {getActionByType(actions,getCodeFromType('Solapa Flotante')).map((action => (
        <FlapButton
          actions={actions}
          developments={action.developments} 
          development={isViewDevelopment ? development : undefined} 
          name={action.name}
          linkTo={action.texts.text_URL}
          utmValue={action.texts.text_utm}
          linkTxt={action.texts.text_limited}
          alignment={action.alignment}
          type={getTypeFromCode(action.type)}
          showOn={getSectionFromCode(action.section)}/>
      )))}
      {getActionByType(actions,getCodeFromType('PopUp Timer')).map((action => (
        <CounterPopup
          actions={actions}
          developments={action.developments} 
          development={isViewDevelopment ? development : undefined} 
          name={action.name}
          linkTo={action.texts.text_URL} 
          linkTxt={action.texts.text_button} 
          title={action.title}
          showOn={getSectionFromCode(action.section)}
          band={action.texts.text_band} 
          text={action.texts.text}
          utmValue={action.texts.text_utm}
          type={getTypeFromCode(action.type)}
          eventDate={action.date_start}/> 
      )))}
      {getActionByType(actions,getCodeFromType('PopUp Vertical')).map((action => (
        <SquarePop
          actions={actions}
          developments={action.developments} 
          development={isViewDevelopment ? development : undefined}
          name={action.name}
          type={getTypeFromCode(action.type)}
          align={action.alignment} 
          flat={false} 
          linkTo={action.url_destiny}
          image={action.file}
          utmValue={action.text_utm}
          showOn={getSectionFromCode(action.section)} /> 
      )))}
      {getActionByType(actions,getCodeFromType('PopUp Cuadrado')).map((action => (
        <SquarePop
          actions={actions}
          developments={action.developments} 
          development={isViewDevelopment ? development : undefined}
          name={action.name}
          type={getTypeFromCode(action.type)}
          align={""} 
          flat={false} 
          linkTo={action.url_destiny}
          image={action.file}
          utmValue={action.text_utm}
          showOn={getSectionFromCode(action.section)} /> 
        )))}
      {getActionByType(actions,getCodeFromType('PopUp Panoramico')).map((action => (
        <SquarePop
          actions={actions}
          developments={action.developments} 
          development={isViewDevelopment ? development : undefined}
          name={action.name}
          type={getTypeFromCode(action.type)}
          align={""} 
          flat={true} 
          linkTo={action.url_destiny}
          image={action.file}
          utmValue={action.text_utm}
          showOn={getSectionFromCode(action.section)} /> 
      )))}
      {getActionByType(actions,getCodeFromType('Barra Encabezado Timer')).map((action => (
        <AlertTop
          actions={actions}
          developments={action.developments} 
          development={isViewDevelopment ? development : undefined} 
          name={action.name}
          linkTo={action.texts.text_URL}
          linkTxt={action.texts.text_button}
          textAlert={action.texts?.text}
          showOn={getSectionFromCode(action.section)}
          type={getTypeFromCode(action.type)}
          utmValue={action.texts.text_utm}
          eventDate={action.date_start}/>
      )))}
      {getActionByType(actions,getCodeFromType('Barra Encabezado')).map((action => (
        <AlertTop
          actions={actions}
          developments={action.developments} 
          development={isViewDevelopment ? development : undefined} 
          name={action.name}
          linkTo={action.texts.text_URL}
          linkTxt={action.texts.text_button}
          textAlert={action.texts?.text}
          type={getTypeFromCode(action.type)}
          utmValue={action.texts.text_utm}
          showOn={getSectionFromCode(action.section)}/>
      )))}
    </>
  )
}

export default connect(state => ({
  actions:state.actions.actions
}),null)(Modules);