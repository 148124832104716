import { orderNovelties } from "../../helpers/helper.novelty"
import { mediacoreApi } from "../mediacoreApi"

const blogExtended = mediacoreApi.injectEndpoints({
  endpoints: builder => ({
    getBlogs: builder.query({
      query: CLIENT_ID =>
        `blog/novedades/get_data_summary/?client=${CLIENT_ID}`,
      transformResponse: (data) => ({"news":orderNovelties(data.news),"tags":data.tags}),
    }),
    getDetailBlog: builder.query({
      query: ({ CLIENTID, BLOG_ID }) =>
        `blog/novedades/${BLOG_ID}/get_data_detail/?client=${CLIENTID}`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetBlogsQuery, useGetDetailBlogQuery } = blogExtended
